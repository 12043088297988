.anchor {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  @include media($w1024) {
    top: -90px;
  }
  @include media($w768) {
    top: -80px;
  }
  @include media($w560) {
    top: -76px;
  }
}
