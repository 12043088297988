.news {
  margin: 0 0 3.125vw;
  padding: 3.3854166vw 0 0;
  position: relative;
  @include media($w1024) {
    margin: 0 0 40px;
    padding: 35px 0 0;
  }
  @include media($w768) {
    margin: 0 0 30px;
  }
  @include media($w560) {
    margin: 0 0 20px;
  }
  &__content {
    align-items: flex-end;
    display: flex;
    @include media($w768) {
      flex-wrap: wrap;
    }
  }
  &__image {
    background-size: cover;
    background-position: center center;
    width: 37vw;
    height: 37vw;
    position: relative;
    z-index: 25;
    @include media($w1024) {
      width: 50%;
      height: 46vw;
    }
    @include media($w768) {
      width: 420px;
      height: 420px;
    }
    @include media($w560) {
      width: 360px;
      height: 300px;
    }
    &-addon {
      border-right: 2.34375vw solid $gray-4;
      border-bottom: 2.34375vw solid $gray-4;
      width: 100%;
      height: 100%;
      pointer-events: none;
      display: block;
      position: absolute;
      z-index: -25;
      top: 2.34375vw;
      left: 2.34375vw;
      @include media($w1024) {
        border-right-width: 25px;
        border-bottom-width: 25px;
        top: 25px;
        left: 25px;
      }
      @include media($w560) {
        display: none;
      }
    }
    a {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 25;
      top: 0;
      left: 0;
    }
  }
  &__copy {
    padding: 0 0 2.604166vw 5.46875vw;
    flex: 1 1 auto;
    position: relative;
    z-index: 50;
    @include media($w1024) {
      padding: 0 0 35px 50px;
    }
    @include media($w768) {
      width: 100%;
      padding: 58px 0 0 25px;
    }
    @include media($w560) {
      padding: 32px 0 0;
    }
    time {
      font-size: 1.04166vw;
      line-height: 1.04166vw;
      color: $gray-2;
      margin: 0 0 1.5625vw;
      display: block;
      @include media($w1024) {
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 20px;
      }
    }
    h3 {
      max-width: 20.0520833vw;
      font-size: 1.979166vw;
      line-height: 2.5vw;
      margin: 0 0 3.125vw;
      @include media($w1024) {
        max-width: 250px;
        font-size: 24px;
        line-height: 30px;
        margin: 0 0 30px;
      }
      @include media($w768) {
        max-width: 300px;
        margin: 0 0 20px;
      }
      @include media($w560) {
        max-width: 360px;
      }
      a {
        background: linear-gradient(to right, rgba($gray-1,1) 0%, rgba($gray-1,1) 98%);
    		background-size: 0% 0.15625vw;
    		background-repeat: no-repeat;
    		background-position: left 100%;
        display: inline;
        @include media($w1024) {
          background-size: 0% 2px;
        }
        &:hover {
    			background-size: 100% 0.15625vw;
          @include media($w1024) {
            background-size: 100% 2px;
          }
    		}
      }
    }
    & > a {
      display: inline-block;
      img {
        width: 3.229166vw;
        @include media($w1024) {
          width: 40px;
        }
      }
    }
  }
  &__button {
    padding: 6.25vw 0 0;
    @include media($w1024) {
      padding: 70px 0 0;
    }
    @include media($w768) {
      padding: 60px 0 0;
    }
    @include media($w560) {
      padding: 50px 0 0;
    }
  }
  &--post {
    .news__image {
      width: 30.729166vw;
      height: 30.729166vw;
      @include media($w1024) {
        width: 50%;
        height: 46vw;
      }
      @include media($w768) {
        width: 420px;
        height: 420px;
      }
      @include media($w560) {
        width: 360px;
        height: 300px;
      }
      &-addon {
        border-right-width: 1.8229166vw;
        border-bottom-width: 1.8229166vw;
        top: 1.8229166vw;
        left: 1.8229166vw;
        @include media($w1024) {
          border-right-width: 25px;
          border-bottom-width: 25px;
          top: 25px;
          left: 25px;
        }
      }
    }
    .news__copy {
      padding: 0 0 2.95vw 4.4270833vw;
      @include media($w1024) {
        padding: 0 0 35px 50px;
      }
      @include media($w768) {
        width: 100%;
        padding: 58px 0 0 25px;
      }
      @include media($w560) {
        padding: 32px 0 0;
      }
      h3 {
        font-size: 1.770833vw;
        line-height: 2.1875vw;
        @include media($w1024) {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
  &--subpage {
    margin: 0;
    padding: 0 0 2.604166vw; // 0 0 50px
    @include media($w1024) {
      padding: 0 0 30px;
    }
    @include media($w768) {
      padding: 0 0 20px;
    }
    @include media($w560) {
      padding: 0 0 10px;
    }
    .news__content {
      margin: 5.20833vw 0 0; // 100px 0 0
      @include media($w1024) {
        margin: 60px 0 0;
      }
      @include media($w768) {
        margin: 50px 0 0;
      }
      @include media($w560) {
        margin: 40px 0 0;
      }
    }
  }
}
