.status-page {
  &__content {
    padding: 0.520833vw 0 2.0833vw;
    @include media($w1024) {
      padding: 5px 0 40px;
    }
    @include media($w768) {
      padding: 5px 0 30px;
    }
    @include media($w560) {
      padding: 5px 0 20px;
    }
  }
  &__block {
    &-title {
      padding: 4.166vw 0 3.90625vw;
      @include media($w1024) {
        padding: 35px 0 30px;
      }
      @include media($w768) {
        padding: 30px 0 25px;
      }
      h3 {
        font-family: futura-pt, sans-serif !important;
        font-size: 1.875vw;
        font-weight: 500;
        line-height: 2.395833vw;
        @include media($w1024) {
          font-size: 24px;
          line-height: 30px;
        }
        @include media($w560) {
          font-size: 22px;
          line-height: 28px;
        }
        strong {
          width: 5.20833vw;
          vertical-align: baseline;
          display: inline-block;
          @include media($w1024) {
            width: 65px;
          }
          @include media($w560) {
            width: 60px;
          }
        }
      }
    }
    ol {
      font-size: 1.04166vw;
      line-height: 1.5625vw;
      counter-reset: counter;
      margin: 0 0 1.5625vw;
      @include media($w1024) {
        font-size: 15px !important;
        line-height: 25px !important;
        margin: 0 0 25px;
      }
      @include media($w560) {
        margin: 0 0 20px;
      }
      li {
        counter-increment: counter;
        padding: 0 0 0 2.34375vw;
        position: relative;
        @include media($w1024) {
          padding: 0 0 0 30px;
        }
        & + li {
          margin: 0.9375vw 0 0;
          @include media($w1024) {
            margin: 30px 0 0;
          }
          @include media($w560) {
            margin: 20px 0 0;
          }
        }
        &:before {
          content: counter(counter) '.';
          font-family: futura-pt-bold, sans-serif !important;
          font-weight: 700;
          position: absolute;
          display: block;
          left: 0;
        }
        & > ol {
          counter-reset: counterDots;
          margin: 0.9375vw 0 0;
          @include media($w1024) {
            margin: 8px 0 0;
          }
          @include media($w560) {
            margin: 5px 0 0;
          }
          li {
            counter-increment: counterDots;
            padding: 0 0 0 2.604166vw;
            @include media($w1024) {
              padding: 0 0 0 35px;
            }
            &:before {
              content: counter(counter) '.' counter(counterDots);
            }
          }
        }
      }
    }
    ul {
      font-size: 1.04166vw;
      line-height: 1.5625vw;
      margin: 0 0 1.5625vw;
      @include media($w1024) {
        font-size: 15px !important;
        line-height: 25px !important;
        margin: 0 0 25px;
      }
      @include media($w560) {
        margin: 0 0 20px;
      }
      li {
        padding: 0 0 0 2.34375vw;
        position: relative;
        @include media($w1024) {
          padding: 0 0 0 30px;
        }
        & + li {
          margin: 0.9375vw 0 0;
          @include media($w1024) {
            margin: 8px 0 0;
          }
          @include media($w560) {
            margin: 5px 0 0;
          }
        }
        &:before {
          border-radius: 50%;
          background-color: $gray-1;
          width: 0.3125vw;
          height: 0.3125vw;
          position: absolute;
          display: block;
          top: 0.6770833vw;
          left: 0.3125vw;
        }
        & > ul {
          margin: 0.9375vw 0 0;
          @include media($w1024) {
            margin: 8px 0 0;
          }
          @include media($w560) {
            margin: 5px 0 0;
          }
        }
      }
    }
    p {
      margin: 0 0 1.5625vw;
      @include media($w1024) {
        margin: 0 0 30px;
      }
      @include media($w560) {
        margin: 0 0 20px;
      }
    }
    & > * {
      &:last-child {
        margin: 0;
      }
    }
  }
}
