.about-page {
  padding: 4.6875vw 0 1.3020833vw;
  @include media($w1024) {
    padding: 45px 0 10px;
  }
  @include media($w768) {
    padding: 42px 0 0;
  }
  &__content {
    display: flex;
    @include media($w768) {
      flex-wrap: wrap;
    }
    h4 {
      font-size: 1.45833vw;
      line-height: 2.5vw;
      margin: 0 0 4.4270833vw;
      @include media($w1024) {
        font-size: 18px;
        line-height: 28px;
        margin: 0 0 40px;
      }
      @include media($w560) {
        margin: 0 0 35px;
      }
    }
    p {
      margin: 0 0 1.04166vw;
      @include media($w1024) {
        margin: 0 0 25px;
      }
      @include media($w560) {
        margin: 0 0 20px;
      }
    }
    .btn {
      margin: 1.04166vw 0 0;
      @include media($w1024) {
        margin: 5px 0 0;
      }
    }
  }
  &__left {
    width: 60%;
    padding: 0 2.34375vw 0 7.8125vw;
    position: relative;
    z-index: 25;
    @include media($w1024) {
      width: 55%;
      padding: 0 25px 0 0;
    }
    @include media($w768) {
      width: 100%;
      padding: 0;
    }
    figure {
      width: calc(100% + 7.8125vw);
      margin: 0 0 4.4270833vw -7.8125vw;
      position: relative;
      @include media($w1024) {
        width: 100%;
        margin: 0 0 60px;
      }
      @include media($w768) {
        margin: 0 0 40px;
      }
      @include media($w560) {
        margin: 0 0 35px;
      }
      &:after {
        background-color: $gray-4;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        z-index: 25;
        top: 2.34375vw;
        left: 2.34375vw;
        @include media($w1024) {
          top: 25px;
          left: 25px;
        }
        @include media($w768) {
          display: none;
        }
      }
      img {
        width: 100%;
        position: relative;
        z-index: 50;
      }
    }
  }
  &__right {
    width: 40%;
    padding: 5.729166vw 0 0 2.34375vw;
    position: relative;
    z-index: 50;
    @include media($w1024) {
      width: 45%;
      padding: 58px 0 0 25px;
    }
    @include media($w768) {
      width: 100%;
      padding: 0;
    }
  }
}
