.contact {
  padding: 3.3854166vw 0 0;
  position: relative;
  @include media($w1024) {
    padding: 35px 0 0;
  }
  &__content {
    background-color: $gray-2;
    color: $white;
    padding: 3.125vw 3.90625vw;
    display: flex;
    @include media($w1024) {
      padding: 35px 30px;
    }
    @include media($w768) {
      flex-wrap: wrap;
    }
    @include media($w560) {
      width: calc(100% + 60px);
      margin: 0 -30px;
    }
  }
  &__left,
  &__right {
    width: 50%;
    @include media($w768) {
      width: 100%;
    }
    h4 {
      font-family: 'Effloresce' !important;
      font-size: 3.75vw;
      font-weight: 400;
      line-height: 3.75vw;
      margin: 0 0 2.0833vw;
      @include media($w1024) {
        font-size: 30px;
        line-height: 30px;
        margin: 0 0 15px;
      }
    }
    p {
      margin: 0 0 2.0833vw;
      @include media($w1024) {
        margin: 0 0 15px;
      }
    }
    a {
      color: $white;
      position: relative;
      &:after {
        @include transition;
        background-color: $white;
        width: 0%;
        height: 1px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
    * {
      &:last-child {
        margin: 0;
      }
    }
  }
  &__left {
    @include media($w768) {
      margin: 0 0 15px;
    }
  }
}
