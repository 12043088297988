.btn {
	@include transition;
	font-size: 1.145833vw;
	line-height: 1.5625vw;
	text-transform: uppercase;
	text-decoration: none;
	color: $gray-1;
	padding: 0 5.729166vw 0 0;
	display: inline-block;
	position: relative;
	@include media($w1024) {
		font-size: 15px;
		font-weight: 600;
		line-height: 19px;
		padding: 0 60px 0 0;
	}
	&:before,
	&:after {
		@include transition;
		@include translateY;
		background-image: url(../images/icon--arrow-btn.svg);
		background-size: cover;
		width: 3.229166vw;
		height: 0.3833vw;
		display: block;
		position: absolute;
		right: 0;
		@include media($w1024) {
			width: 40px;
			height: 5px;
		}
	}
	&:before {
		display: none;
		right: auto;
		left: 0;
	}
	&:hover {
		color: $gray-1;
		padding: 0 4.9479166vw 0 0;
		@include media($w1024) {
			padding: 0 50px 0 0;
		}
	}
	&--white {
		color: $white;
		&:before,
		&:after {
			background-image: url(../images/icon--arrow-btn--white.svg);
		}
		&:hover {
			color: $white;
		}
	}
	&--front {
		padding: 0 0 0 5.729166vw;
		@include media($w1024) {
			padding: 0 0 0 60px;
		}
		&:before {
			display: block;
		}
		&:after {
			display: none;
		}
		&:hover {
			padding: 0 0 0 5.729166vw;
			@include media($w1024) {
				padding: 0 0 0 60px;
			}
			&:before {
				left: 0.78125vw;
				@include media($w1024) {
					left: 10px;
				}
			}
		}
	}
}
