a {
	@include transition;
	text-decoration: none;
	color: $gray-1;
	&:hover {
		color: $gray-1;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: futura-pt-bold, sans-serif !important;
	font-weight: 700;
	margin: 0;
}

p {
	font-size: 1.145833vw;
	line-height: 1.770833vw;
	margin: 0;
	@include media($w1024) {
		font-size: 15px;
		line-height: 25px;
	}
}

ul {
	li {
		position: relative;
	}
}

strong, b {
	font-family: futura-pt-bold, sans-serif !important;
	font-weight: 700;
}
