.partners {
  border-top: 1px solid $gray-3;
  border-bottom: 1px solid $gray-3;
  background-color: $gray-4;
  margin: 0;
  padding: 3.125vw 0 2.604166vw;
  position: relative;
  z-index: 25;
  @include media($w1024) {
    padding: 35px 0;
  }
  @include media($w768) {
    padding: 35px 0 0;
  }
  @include media($w560) {
    padding: 32px 0 10px;
  }
  .section-title {
    &:after {
      display: none;
    }
    h4 {
      background-color: transparent;
      padding: 0;
    }
  }
  &__content {
    display: flex;
    @include media($w768) {
      flex-wrap: wrap;
    }
  }
  &__item {
    border: 1px solid $gray-3;
    background-color: $white;
    width: calc(25% - 1.171875vw);
    height: 7.03125vw;
    margin: 0 0 0 1.5625vw;
    display: block;
    position: relative;
    @include media($w1024) {
      width: calc(25% - 20px);
      height: 90px;
      margin: 0 0 0 30px;
    }
    @include media($w768) {
      width: calc(50% - 15px);
      height: 80px;
      margin: 0 0 30px 30px !important;
    }
    @include media($w560) {
      width: 100%;
      margin: 0 0 20px !important;
    }
    &:nth-child(4n+1) {
      margin: 0;
    }
    &:nth-child(2n+1) {
      @include media($w768) {
        margin: 0 0 30px !important;
      }
      @include media($w560) {
        margin: 0 0 20px !important;
      }
    }
    img {
      @include translate;
      max-width: 10vw;
      max-height: 4.5633vw;
      position: relative;
      z-index: 25;
      @include media($w1024) {
        max-width: 70%;
        max-height: 60px;
      }
      @include media($w768) {
        max-height: 50px;
      }
    }
    .btn {
      @include translateY;
      z-index: 25;
      @include media($w1024) {
        top: calc(50% - 2px);
      }
      @include media($w560) {
        width: 100%;
        text-align: center;
        padding: 0;
      }
    }
  }
  &__link {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 50;
    top: 0;
    left: 0;
  }
}
