.footer {
  padding: 0 0 2.1875vw;
  &__inner {
    @include media($w1024) {
      text-align: center;
    }
    padding: 0 3.90625vw;
    justify-content: space-between;
    align-items: center;
    display: flex;
    @include media($w1024) {
      padding: 0 30px;
    }
    @include media($w768) {
      flex-wrap: wrap;
    }
    @include media($w560) {
      padding: 0;
    }
    p {
      text-transform: uppercase;
      @include media($w768) {
        width: 100%;
        text-transform: none;
        margin: 0 0 10px;
      }
    }
    figure {
      @include media($w1024) {
        margin: 0 auto 0 35px;
      }
      img {
      }
    }
  }
  .container {
    @include media($w1024) {
      width: calc(100% + 60px);
      margin: 0;
      background-color: #828282;
      padding-right: 0;
    }
  }
  .dot_buttons {
    display: flex;
    @include media($w1024) {
      
    }
  }
  .dot_links {
    background-color: #828282;
    padding: 0 3.90625vw 3.125vw;
    margin-bottom: 2.1875vw;
    display: flex;
    @include media($w1024) {
      white-space: nowrap;
      overflow-x: scroll;
      padding-left: 0;
      padding-bottom: 35px;
    }
  }
  .dot_button {
    font-size: 16px;
    font-weight: bold;
    line-height: 37px;
    letter-spacing: 0.01em;
    padding: 5px 20px;
    margin-right: 15px;
    background-color: #dadada;
    color: #575756;
    border: 0; 
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    cursor: pointer;
  }
  .dot_button:hover {
    cursor: pointer;
    background-color: #FEFEFE;
  }
  .dot_button:focus,
  .dot_input:focus {
    background-color: #FEFEFE;
  }
  .dot_input {
    width: 102px;
    text-align: center;
    font-size: 16px;
    line-height: 37px;
    background-color: #dadada;
    border: #dadada;
  }
  .dot_input::placeholder {
    color: #575756;
    font-weight: bold;
  }
  .dot_text {
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 1.145833vw;
    line-height: 28px;
    flex: 0 0 25%;
    @include media($w1024) {
      display: none;
    }
  }
  .go_to_dot {
    filter: grayscale(100%);
    opacity: .5;
  }
  .go_to_dot.amount {
    filter: none;
    opacity: 1;
  }
  .go_to_dot.amount:hover {
    cursor: pointer;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.dot_text__mobile{
  display: none;
  @include media($w1024) {
    display: block;
    margin-bottom: -15px;
  }
}
