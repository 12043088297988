.body {
  padding: 0 0 0 26vw;
  @include media($w1024) {
    padding: 90px 0 0;
  }
  @include media($w768) {
    padding: 80px 0 0;
  }
  @include media($w560) {
    padding: 76px 0 0;
  }
}
