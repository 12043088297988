.sidebar {
  border-right: 1px solid $gray-3;
  width: 26vw;
  height: 100vh;
  padding: 2.34375vw 0 0 3.90625vw;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  @include media($w1024) {
    @include transition;
    border: 0;
    width: 100%;
    height: 90px;
    padding: 0 30px;
    align-items: center;
    display: flex;
    z-index: 1000;
  }
  @include media($w768) {
    height: 80px;
  }
  @include media($w560) {
    height: 76px;
  }
  &__logo {
    @include media($w1024) {
      padding: 10px 0 0;
    }
    a {
      display: inline-block;
      img {
        width: 18.229166vw;
        @include media($w1024) {
          width: 160px;
        }
        @include media($w560) {
          width: 150px;
        }
      }
    }
  }
  &__inner {
    @include translateY;
    position: absolute;
    top: calc(50% + 0.520833vw);
    @include media($w1024) {
      @include transition;
      @include translateReset;
      background-color: darken($gray-1,10%);
      width: 100%;
      height: 100vh;
      opacity: 0;
      pointer-events: none;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      display: flex;
      position: absolute;
      top: 0;
      left: 100%;
    }
    &.visible {
      @include media($w1024) {
        opacity: 1;
        pointer-events: auto;
        left: 0;
      }
    }
  }
  &__menu {
    margin: 0 0 6.25vw;
    @include media($w1024) {
      width: 100%;
      text-align: center;
      margin: 0;
    }
    ul {
      li {
        & + li {
          margin: 1.04166vw 0 0;
          @include media($w1024) {
            margin: 15px 0 0;
          }
        }
        a {
          font-size: 1.145833vw;
        	line-height: 1.875vw;
        	text-transform: uppercase;
          display: inline-block;
          position: relative;
          @include media($w1024) {
            font-size: 15px !important;
          	line-height: 21px !important;
            color: $white;
          }
          &:after {
            @include transition;
            background-color: $gray-1;
            width: 0%;
            height: 0.104166vw;
            display: block;
            position: absolute;
            bottom: -0.15625vw;
            left: 0;
          }
          &:hover {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__fb {
    @include media($w1024) {
      width: 100%;
      text-align: center;
    }
    a {
      font-size: 1.145833vw;
      line-height: 1.875vw;
      text-transform: uppercase;
      @include media($w1024) {
        font-size: 15px;
        line-height: 21px;
        color: $white;
      }
      img {
        height: 1.5625vw;
        vertical-align: middle;
        margin: 0 0 0 0.78125vw;
        display: inline-block;
        position: relative;
        bottom: 0.15625vw;
        @include media($w1024) {
          height: 20px;
          margin: 0 0 0 10px;
          bottom: 2px;
        }
      }
    }
  }
  &__trigger {
    width: 22px;
    height: 18px;
    cursor: pointer;
    display: none;
    position: absolute;
    top: 37px;
    right: 30px;
    @include media($w1024) {
      display: block;
    }
    @include media($w768) {
      top: 32px;
    }
    @include media($w560) {
      top: 30px;
    }
    span {
      @include transition;
      @include translateY;
      width: 100%;
      background-color: darken($gray-1,10%);
      height: 2px;
      position: absolute;
      left: 0;
      &:before,
      &:after {
        @include transition;
        background-color: darken($gray-1,10%);
        width: 100%;
        height: 2px;
        display: block;
        position: absolute;
        left: 0;
      }
      &:before {
        top: -7px;
      }
      &:after {
        bottom: -7px;
      }
    }
    &.visible {
      span {
        background-color: transparent;
        &:before,
        &:after {
          background-color: $white;
        }
        &:before {
          transform: rotate(45deg);
          top: 0;
        }
        &:after {
          transform: rotate(-45deg);
          bottom: 0;
        }
      }
    }
  }
  &__support {
    border-top: 0.104166vw solid $gray-3;
    width: calc(100% - 3.90625vw);
    height: 5.9375vw;
    position: absolute;
    bottom: 0;
    right: 0;
    @include media($w1024) {
      display: none;
    }
    .btn {
      line-height: 5.9375vw;
    }
  }
  &.scrolling {
    @include media($w1024) {
      background-color: $white;
      box-shadow: 0px 5px 30px 0px rgba(0,0,0,0.1);
    }
  }
}
