.section-title {
  margin: 0 0 3.125vw;
  position: relative;
  @include media($w1024) {
    margin: 0 0 30px;
  }
  &:after {
    @include translateY;
    background-color: $gray-3;
    width: calc(100% - 10px);
    height: 1.5px;
    display: block;
    position: absolute;
    z-index: 25;
    left: 0;
    @include media($w1024) {
      height: 1px;
    }
  }
  h4 {
    background-color: $white;
    font-family: futura-pt, sans-serif !important;
    font-size: 1.145833vw;
    font-weight: 500;
  	line-height: 1.770833vw;
    text-transform: uppercase;
    padding: 0 3.125vw 0 0;
    display: inline-block;
    position: relative;
    z-index: 50;
    @include media($w1024) {
      font-size: 18px;
    	line-height: 18px;
      padding: 0 20px 0 0;
    }
  }
}
