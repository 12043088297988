.status {
  padding: 1.5625vw 0 0;
  position: relative;
  @include media($w1024) {
    padding: 30px 0 0;
  }
  &__content {
    background-color: $gray-4;
    padding: 2.604166vw 2.604166vw 7.8125vw 7.8125vw;
    display: flex;
    @include media($w1024) {
      padding: 30px;
    }
    @include media($w560) {
      width: calc(100% + 60px);
      margin: 0 -30px;
    }
  }
  &__copy {
    padding: 3.125vw 2.604166vw 0 0;
    @include media($w1024) {
      padding: 30px 30px 30px 0;
    }
    @include media($w768) {
      padding: 20px 0 15px;
    }
    @include media($w560) {
      padding: 15px 0 10px;
    }
    h2 {
      font-family: 'Effloresce' !important;
      font-size: 3.75vw;
      font-weight: 400;
      line-height: 3.75vw;
      margin: 0 0 3.3854166vw;
      @include media($w1024) {
        font-size: 40px;
        line-height: 40px;
        margin: 0 0 30px;
      }
      @include media($w768) {
        font-size: 36px;
        line-height: 36px;
        margin: 0 0 25px;
      }
      @include media($w560) {
        font-size: 32px;
        line-height: 32px;
      }
    }
    p {
      color: $gray-2;
      margin: 0 0 6.5104166vw;
      @include media($w1024) {
        margin: 0 0 30px;
      }
      @include media($w768) {
        margin: 0 0 25px;
      }
    }
  }
  &__image {
    @include media($w768) {
      display: none;
    }
    img {
      width: 23.4375vw;
      @include media($w1024) {
        width: 250px;
      }
    }
  }
}
