.projects {
  .btn--front {
    z-index: 9;
  }
  padding: 3.3854166vw 0 0;
  position: relative;
  z-index: 50;
  @include media($w1024) {
    padding: 35px 0 0;
  }
  &__content {
    flex-wrap: wrap;
    display: flex;
  }
  &__item {
    @include transition;
    border: 1px solid $gray-3;
    background-color: $white;
    width: calc(33.33% - 1.04166vw);
    margin: 5.5729166vw 0 0 1.5625vw; // 107px 0 0 30px
    padding: 0 2.0833vw 2.0833vw;
    position: relative;
    @include media($w1024) {
      width: calc(33.33% - 16px);
      margin: 60px 0 0 24px;
      padding: 0 25px 25px;
    }
    @include media($w768) {
      width: 100%;
      margin: 60px 0 0 !important;
      padding: 0 25px 25px;
    }
    &:nth-child(1) {
      margin: 2.1875vw 0 0; // 42px 0 0
      @include media($w1024) {
        margin: 30px 0 0;
      }
      @include media($w768) {
        margin: 30px 0 0 !important;
      }
    }
    &:nth-child(2),
    &:nth-child(3) {
      margin: 2.1875vw 0 0 1.5625vw; // 42px 0 0 30px
      @include media($w1024) {
        margin: 30px 0 0 24px;
      }
    }
    &:nth-child(3n+4) {
      margin: 5.5729166vw 0 0; // 107px 0 0
      @include media($w1024) {
        margin: 60px 0 0;
      }
    }
    &:hover {
      background-color: $gray-4;
      .projects__title {
        h3 {
          span {
            background-size: 100% 2px;
          }
        }
      }
    }
  }
  &__thumbnail {
    background-size: cover;
    background-position: center center;
    width: 15.104166vw;
    max-width: 100%;
    height: 11.354166vw;
    margin: -2.1875vw 0 2.0833vw;
    @include media($w1024) {
      width: 170px;
      height: 120px;
      margin: -30px 0 20px;
    }
  }
  &__category {
    font-size: 1.04166vw;
    line-height: 1.04166vw;
    color: $gray-2;
    @include media($w1024) {
      font-size: 14px;
      line-height: 18px;
    }
    @include media($w768) {
      margin: 0 0 20px;
    }
  }
  &__title {
    min-height: 10.9375vw;
    margin: 0 0 2.0833vw;
    align-items: flex-end;
    display: flex;
    @include media($w1024) {
      min-height: 100px;
      margin: 0 0 20px;
    }
    @include media($w768) {
      min-height: auto;
    }
    h3 {
      font-family: futura-pt, sans-serif !important;
      font-size: 1.5625vw;
      font-weight: 500;
      line-height: 2.0833vw;
      @include media($w1024) {
        font-size: 17px;
        line-height: 23px;
      }
      span {
        @include transition;
        background: linear-gradient(to right, rgba($gray-1,1) 0%, rgba($gray-1,1) 98%);
        background-size: 0% 2px;
        background-repeat: no-repeat;
        background-position: left 100%;
        display: inline;
      }
    }
  }
  &__link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__button {
    padding: 3.125vw 0; // 60px 0
    @include media($w1024) {
      padding: 35px 0;
    }
  }
}
