.post-page {
  padding: 4.6875vw 0 1.3020833vw;
  @include media($w1024) {
    padding: 45px 0 15px;
  }
  @include media($w768) {
    padding: 45px 0 10px;
  }
  @include media($w560) {
    padding: 40px 0 5px;
  }
  h3 {
    font-size: 1.77083vw;
    line-height: 2.5vw;
    margin: 0 0 2vw;
    @include media($w1024) {
      font-size: 26px;
      line-height: 36px;
      margin: 0 0 40px;
    }
    @include media($w560) {
      font-size: 22px;
      line-height: 32px;
      margin: 0 0 35px;
    }
  }
  h4 {
    font-size: 1.45833vw;
    line-height: 2.5vw;
    margin: 0 0 4.6875vw;
    @include media($w1024) {
      font-size: 24px;
      line-height: 34px;
      margin: 0 0 40px;
    }
    @include media($w560) {
      font-size: 20px;
      line-height: 30px;
      margin: 0 0 35px;
    }
  }
  h5 {
    font-size: 1.25vw;
    line-height: 1.770833vw;
    margin: 0 0 2vw;
    @include media($w1024) {
      font-size: 22px;
      line-height: 32px;
      margin: 0 0 40px;
    }
    @include media($w560) {
      font-size: 18px;
      line-height: 28px;
      margin: 0 0 35px;
    }
  }
  p {
    margin: 0 0 2.0833vw;
    @include media($w1024) {
      margin: 0 0 25px;
    }
    @include media($w560) {
      margin: 0 0 20px;
    }
    i, em {
      font-style: italic;
    }
  }
  blockquote {
    line-height: 0;
    margin: 0;
    padding: 0;
    p {
      font-style: italic;
      padding: 0 0 0 3.3854166vw;
      position: relative;
      @include media($w1024) {
        padding: 0 0 0 50px;
      }
      @include media($w560) {
        padding: 0 0 0 45px;
      }
      &:before {
        background-image: url(../images/quote-sign.svg);
        background-size: cover;
        width: 2.34375vw;
        height: 2.34375vw;
        opacity: 0.1;
        display: block;
        position: absolute;
        z-index: -1;
        top: 0.2604166vw;
        left: 0;
        @include media($w1024) {
          width: 35px;
          height: 35px;
          top: 5px;
        }
        @include media($w560) {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  figure {
    width: calc(100% + 3.125vw);
    margin: 0 -1.5625vw 4.6875vw;
    @include media($w1024) {
      width: calc(100% + 60px);
      margin: 0 -30px 40px;
    }
    @include media($w560) {
      margin: 0 -30px 35px;
    }
    img {
      max-width: 100%;
      height: auto !important;
      margin: 0 auto;
    }
  }
}
