.page-concept {
  background-size: cover;
  background-position: center center;
  height: 31.25vw;
  position: relative;
  @include media($w1024) {
    height: 320px;
  }
  @include media($w768) {
    height: 280px;
  }
  @include media($w560) {
    height: 240px;
  }
  &__inner {
    color: $white;
    padding: 0 9.375vw;
    position: absolute;
    bottom: 4.4270833vw;
    left: 0;
    @include media($w1024) {
      padding: 0 30px;
      bottom: 40px;
    }
    @include media($w768) {
      bottom: 35px;
    }
    @include media($w560) {
      bottom: 30px;
    }
    time {
      font-size: 1.25vw;
    	line-height: 1.770833vw;
      margin: 0 0 3.4vw;
      display: block;
      @include media($w1024) {
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 30px;
      }
      @include media($w768) {
        margin: 0 0 25px;
      }
      @include media($w560) {
        margin: 0 0 20px;
      }
    }
    h1 {
      max-width: 53.125vw;
      font-family: 'Effloresce' !important;
      font-size: 4.166vw;
      font-weight: 400;
      line-height: 4.166vw;
      @include media($w1024) {
        max-width: 100%;
        font-size: 48px;
        line-height: 48px;
      }
      @include media($w768) {
        font-size: 42px;
        line-height: 42px;
      }
      @include media($w560) {
        font-size: 36px;
        line-height: 36px;
      }
    }
  }
  &--standard {
    .page-concept__inner {
      h1 {
        max-width: 50vw;
        font-family: futura-pt-bold, sans-serif !important;
        font-size: 3.54166vw;
        font-weight: 700;
        line-height: 4.0625vw;
        @include media($w1024) {
          max-width: 100%;
          font-size: 42px;
          line-height: 48px;
        }
        @include media($w768) {
          font-size: 36px;
          line-height: 42px;
        }
        @include media($w560) {
          font-size: 30px;
          line-height: 36px;
        }
      }
    }
  }
}
