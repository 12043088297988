.kv {
  background-size: cover;
  background-position: center center;
  height: 100vh;
  position: relative;
  @include media($w1024) {
    height: 600px;
  }
  @include media($w768) {
    height: 500px;
  }
  @include media($w560) {
    height: 450px;
  }
  &__inner {
    width: 100%;
    padding: 0 9.375vw;
    position: absolute;
    bottom: 5.729166vw;
    left: 0;
    @include media($w1024) {
      padding: 0 30px;
      bottom: 60px;
    }
    @include media($w768) {
      bottom: 50px;
    }
    @include media($w560) {
      bottom: 40px;
    }
    h1 {
      font-family: 'Effloresce' !important;
      font-size: 7.395833vw;
      font-weight: 400;
      line-height: 7.395833vw;
      color: $white;
      margin: 0 0 4.166vw;
      @include media($w1024) {
        font-size: 56px;
        line-height: 56px;
        margin: 0 0 40px;
      }
      @include media($w768) {
        font-size: 48px;
        line-height: 48px;
        margin: 0 0 30px;
      }
      @include media($w560) {
        font-size: 40px;
        line-height: 40px;
        margin: 0 0 25px;
      }
    }
  }
  &__copy {
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    @include media($w768) {
      flex-wrap: wrap;
    }
    p {
      max-width: 34vw;
      color: $gray-2;
      @include media($w1024) {
        max-width: 50%;
      }
      @include media($w768) {
        max-width: 100%;
        margin: 0 0 30px;
      }
      @include media($w560) {
        margin: 0 0 25px;
      }
    }
  }
}
