// font-family: futura-pt, sans-serif;
// font-family: futura-pt-bold, sans-serif;
// font-family: 'Effloresce';

* {
	@include box-sizing;
	&:before,
	&:after {
		@include box-sizing;
		content: '';
		display: none;
	}
}

::-moz-selection {
	background: $gray-1;
	color: $white;
}

::selection {
	background: $gray-1;
	color: $white;
}

body {
	background-color: $white;
	font-family: futura-pt, sans-serif !important;
	font-weight: 500;
	color: $gray-1;
	margin: 0;
	padding: 0;
}

img {
	margin: 0;
	display: block;
	user-select: none;
}

/* --- [ global styles ] --- */

.wrapper {
	width: 100%;
	overflow: hidden;
}

.container {
	max-width: 100%;
	margin: 0 auto;
	padding: 0 3.645833vw 0 1.5625vw;
	position: relative;
	@include media($w1024) {
    padding: 0 30px;
  }
	&--tiny {
		padding: 0 9.375vw;
		@include media($w1024) {
	    padding: 0 30px;
	  }
	}
}

.clearfix {
	&:after {
		clear: both;
		display: table;
	}
}
