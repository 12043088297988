.project-page {
  overflow: hidden;
  padding: 4.6875vw 0 1.3020833vw;
  @include media($w1024) {
    padding: 45px 0;
  }
  @include media($w768) {
    padding: 40px 0 30px;
  }
  @include media($w560) {
    padding: 40px 0 20px;
  }
  &__title {
    padding: 0 1.5625vw 0 0;
    @include media($w1024) {
      padding: 0;
    }
    h4 {
      font-size: 1.45833vw;
      line-height: 2.395833vw;
      margin: 0 0 3.3854166vw;
      @include media($w1024) {
        font-size: 18px;
        line-height: 28px;
        margin: 0 0 35px;
      }
      @include media($w768) {
        margin: 0 0 25px;
      }
    }
  }
  &__content {
    align-items: flex-start;
    display: flex;
    figure {
      margin: -1.8229166vw -5.46875vw 0 0;
      flex: 0 0 23.4375vw;
      position: relative;
      z-index: 25;
      @include media($w1024) {
        margin: 10px 0 0;
        flex: 0 0 250px;
      }
      @include media($w768) {
        flex: 0 0 200px;
      }
      @include media($w560) {
        display: none;
      }
      &:after {
        background-color: $gray-4;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        z-index: 25;
        top: 2.34375vw;
        right: 2.34375vw;
        @include media($w1024) {
          top: 25px;
          right: 25px;
        }
        @include media($w768) {
          display: none;
        }
      }
      img {
        width: 100%;
        position: relative;
        z-index: 50;
      }
    }
  }
  &__copy {
    padding: 0 6.770833vw 0 3.90625vw;
    flex: 1 1 auto;
    position: relative;
    z-index: 50;
    @include media($w1024) {
      padding: 0 50px 0 70px;
    }
    @include media($w768) {
      padding: 0 50px 0 0;
    }
    @include media($w560) {
      padding: 0;
    }
    p {
      margin: 0 0 1.04166vw;
      @include media($w1024) {
        margin: 0 0 25px;
      }
      @include media($w768) {
        margin: 0 0 20px;
      }
    }
    * {
      &:last-child {
        margin: 0;
      }
    }
  }
  &__icon {
    width: 12.812499vw;
    position: absolute;
    z-index: -1;
    top: -5.46875vw;
    right: 100%;
    @include media($w1024) {
      width: 120px;
      top: -34px;
      right: calc(100% - 45px);
    }
    @include media($w768) {
      display: none;
    }
    img {
      width: 100%;
    }
  }
}
